.edit-transaction-form-container {
  background-color: white;

  .edit-transaction-form {
    padding-left: 0;
  }

  input[type=checkbox] {
    float: left;
    margin-top: 18px;
    margin-left: 10px;
  }
}

.edit-transaction-form + div {
  // border-top: none !important;
}

.edit-transaction-form {
  display: flex;
  padding: 20px;
  background-color: white;
  padding-top: 3px;
  padding-bottom: 3px;
//   border-top: 1px solid #ced4da;
  padding-right: 0;

  .transaction-date,
  .transaction-payee,
  .transaction-category,
  .transaction-tag,
  .transaction-notes,
  .transaction-amount {
    margin-right: 20px;
    text-align: left;
  }

  .transaction-controls {
    margin-top: 6px;
  }

  .transaction-controls button {
    padding: 0px 5px;
  }

  .autocomplete-container,
  .transaction-notes {
    width: 17%;
  }

  .transaction-date {
    width: 100px;
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 0;
    padding-left: 0;
  }

  .transaction-balance {
    width: 115px !important;
  }

  .transaction-date.inline {
    padding-left: 0px;
    padding-top: 4px !important;
    margin-right: 0;
  }

  .field-autocomplete.inline {
    margin-right: 0 !important;
  }

  .autocomplete-container {
    flex-grow: 1;
    // border-bottom: 1px solid #ced4da;
    // border-top: 1px solid #ced4da;
  }

  div, input {
    border: none;
    outline: none;
  }

  .react-datepicker-wrapper, .field-text {
    // border-top: 1px solid #ced4da;
    // border-bottom: 1px solid #ced4da;
    // border-left: 1px solid #ced4da;
  }

  .field-autocomplete {
    div:first-of-type, &:focus, &:hover, &:active {
      border-radius: 0 !important;
      outline: none !important;
      border: none !important;
      box-shadow: none;
    }

    div {
      padding-left: 0;
      margin-right: 0;
    }

    span {
      width: auto;
    }
  }
}
