.transactions-container,
.edit-transaction-form {
  .card {
    border: none;
    border-top: 1px solid #ced4da;
    padding-top: 10px;
    padding-bottom: 10px;

    > div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .transaction-date {
    width: 110px;
    margin-right: 20px;
  }
  .transaction-payee {
    margin-right: 20px;
  }
  .transaction-category {
    margin-right: 20px;
  }
  .transaction-tag {
    margin-right: 20px;
  }
  .transaction-notes {
    margin-right: 20px;
  }
  .transaction-amount {
    width: 150px;
    text-align: right;
    margin-right: 20px;
  }
  .transaction-balance {
    width: 150px;
    text-align: right;
    margin-right: 20px;
  }
  .transaction-controls {
    width: 70px;
    text-align: right;
    cursor: pointer;
  }
}

.edit-transaction-form {
  padding-left: 0;

  .transaction-date {
    width: 105px;
  }
}
