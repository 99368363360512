/**
 * Styles for the Sidebar.js component
 */

.sidebar {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
  z-index: 1000;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #22262E;
  border-right: 1px solid #252931;

  .nav {
    margin-bottom: 20px;
  }

  .nav-item {
    width: 100%;

    & + .nav-item {
      margin-left: 0;
    }
  }

  .nav-link {
    color: #6A6E74;
    font-size: 0.9rem;
    padding: 0.75rem 1rem;

    i {
      font-size: 1.2rem;
      margin-right: 0.4rem;
    }

    span {
      position: absolute;
    }

    &.active, &:hover {
      color: white;
    }

    &.active {
      background: #141519;
    }
  }

  .card {
    background: #263238;
    padding: 8px 15px 0px 15px;
    border: none;
    border-bottom: 1px solid rgba(230, 232, 244, 0.219);
    margin: 0;

    a {
      &.active {
        background: #141519;
      }

      color: #E6E8F4;
      &.active, &:hover {
        color: #E6E8F4;
      }

      div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

}
.ant-menu-item-group-title {
    padding: 0 !important;
    height: auto !important;
}

.ant-menu-item {
    margin: 0 !important;
    padding-left: 10px !important;

    &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
