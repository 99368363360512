@import 'antd';
@import 'react-datepicker';
@import 'simplebar';
@import 'themes/dark';
@import 'components/sidebar';
@import 'components/loading';
@import 'components/header';
@import 'components/edit_transaction';
@import 'components/transactions';

// body {
//     background-color: $background-color;
// }

/**
 * Form Overrides
 */

.form-control {
    &:focus {
        outline: none !important;
        box-shadow: none;
        border-color: gray;
        border-bottom-color: white;
    }
}

.btn {
    i {
        font-size: 0.8rem;
    }
}

/**
 * Jumbotron
 */
.jumbotron {
    border-radius: 0;
}

.ant-page-header {
    .ant-page-header-title-view,
    .ant-page-header-back .ant-divider {
        vertical-align: bottom;
    }
}

.ant-page-header-title-view-extra {
    top: 10px;
}

/**
 * Pulsating Loading Animation
 */
.content-loading {
    opacity: 1;
    animation: fadeInOut 1s linear;
}

@keyframes fadeInOut {
    0%,
    100% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.7;
    }
}

.ant-layout-content {
    background-color: white;
    margin: 24px 16px 0;
    padding: 24px;
}

.ant-layout-content .ant-table-tbody > tr > td {
    padding: 14px 7px;
}

.ant-layout-content .ant-table-thead > tr > th {
    padding-left: 7px;

    .ant-input {
        padding-right: 7px;
    }
}
